import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const HowtoPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Standard Packages</h2>
      <p>
	FISH MED NET è un progetto che sostiene la diversificazione dell'attività principale dei pescatori. Ha lo scopo di consentire ai pescatori e alle cooperative di pescatori di
esplorare diversi percorsi al fine di aumentare i servizi con l'obiettivo di migliorare i loro mezzi di sussistenza, l&#39;economia locale e l'ambiente circostante. Una di queste attività è quella di promuovere l&#39;uso delle loro strutture per i servizi alla collettività, in cui possono svolgersi relazioni formalizzate tra soggetti privati e pubblici.
      </p>
	<p>
	Proprio per questo FISH MED NET ha messo a punto 4 diverse tipologie di accordi formali, noti come pacchetti standard, che consentiranno di avviare un percorso formale di confronto tra pescatori e soggetti pubblico-privati.
	</p>
	<p>
	Di seguito sono riportati i link per le quattro diverse tipologie di pacchetti standard. Questi documenti possono essere utilizzati liberamente come modelli e modificati liberamente. Ci auguriamo che questi documenti possano fornire l'aiuto necessario per avviare una collaborazione positiva e proficua tra tutti gli utenti. L'uso di questi documenti è esclusivamente a rischio degli utenti finali e il progetto, i partner e le organizzazioni ENI CBC MED non possono essere ritenuti responsabili per eventuali discrepanze e/o azioni sviluppate da uno qualsiasi degli accordi formalizzati o non formalizzati stipulati e condotti dall'utente finale utenti.
	</p>
<p> <a href="../../standard-packages-it.zip">Download zip file</a> </p>
      <h2>Explore between our last proposals</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default HowtoPage
